var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "storehouse" }, [_vm._v("经销商管理")]),
      _c("ul", { staticClass: "tab-ul" }, [
        _c(
          "li",
          {
            class: { ative: _vm.currentTab == "personalList" },
            on: {
              click: function($event) {
                _vm.toggleTab("personalList")
              }
            }
          },
          [_vm._v("个人")]
        ),
        _c(
          "li",
          {
            class: { ative: _vm.currentTab == "enterpriseList" },
            on: {
              click: function($event) {
                _vm.toggleTab("enterpriseList")
              }
            }
          },
          [_vm._v("企业")]
        )
      ]),
      _c("keep-alive", [_c(_vm.currentTab, { tag: "stock" })], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }