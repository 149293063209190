<template>
    <div>
        <div class="storehouse">经销商管理</div>
        <ul class="tab-ul">
            <li :class="{'ative':currentTab=='personalList'}" @click="toggleTab('personalList')">个人</li>
            <li :class="{'ative':currentTab=='enterpriseList'}" @click="toggleTab('enterpriseList')">企业</li>
        </ul>
        <keep-alive>
      <stock :is="currentTab"></stock>
    </keep-alive>
    </div>
</template>
<script>
    import personalList from './personalList';
    import enterpriseList from './enterpriseList';
    export default {
        components: {
            personalList,
            enterpriseList,
        },
        data() {
            return {
                currentTab: 'personalList',
            }
        },
        activated() {
            let dealerType = this.cache.get('dealerType');
            if(dealerType) {
                this.currentTab = dealerType;
            }else {
                this.currentTab = 'personalList';
            }
        },
        deactivated() {
            let pathList = this.$route.path.split('/')
            if(pathList[3] != 'dealer') {
                this.cache.del('dealerType');
            }
        },
        methods: {
            toggleTab: function (tab) {
                this.currentTab = tab; // tab 为当前触发标签页的组件名
                this.cache.set('dealerType',tab);
            },
        },
    }
</script>
<style lang="scss" scoped>
    .storehouse {
        font-size: 18px;
        color: #303133;
        margin-bottom: 16px;
        padding-top: 8px;
    }
    // tab样式
    .tab-ul {
        list-style: none;
        padding-left: 0;
        margin-top: 0;
        height: 42px;
        box-sizing: border-box;
        margin-bottom: 40px;
        width: 100%;
        border-bottom: 2px solid #e4e7ed;

        li {
            cursor: pointer;
            padding-left: 18px;
            padding-right: 18px;
            height: 42px;
            line-height: 42px;
            float: left;
            text-align: center;
            margin-right: 13px;
            font-size: 15px;
            background-color: #ffffff;
            color: #303133;
            box-sizing: border-box;
            border-bottom: 2px solid #e4e7ed;

            &.ative {
                // color: #ffffff;
                // background: #5e74f1;
                color: #30BAC1;
                border-bottom: 2px solid #30BAC1;
            }
        }

        &::after {
            content: "";
            display: block;
            clear: both;
        }
    }
</style>