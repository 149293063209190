<template>
    <div class="personalList">
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                dialogVisible: false,
                opt: {
                    search: [{
                            key: "name",
                            label: "姓名",
                        },
                        {
                            key: "bankPhone",
                            label: "手机号码",
                        },
                        {
                            key: "state",
                            label: "状态",
                            type: 'select',
                            opt: {
                                list: [{
                                        value: 0,
                                        label: '待审核',
                                    },
                                    {
                                        value: 1,
                                        label: '审核通过',
                                    }
                                ]
                            }
                        },
                    ],
                    columns: [{
                            label: "姓名",
                            key: "name"
                        },
                        {
                            label: "性别",
                            key: "sexStr"
                        },

                        {
                            label: "证件类型",
                            key: "cardTypeStr"
                        },
                        {
                            label: "证件号码",
                            key: "cardNoStr"
                        },
                        {
                            label: "手机号码",
                            key: "bankPhone"
                        },
                        {
                            label: "联系地址",
                            key: "address"
                        },
                        {
                            label: "注册时间",
                            key: "createdTime"
                        },
                        {
                            label: "状态",
                            key: "stateStr"
                        },
                        {
                            label: "操作",
                            key: "action",
                            type: "action-back",
                            opt: {
                                list: [{
                                    label: "审核",
                                    on(row, cb) {
                                        _this.goDetail(row, 1);
                                    }
                                }, {
                                    label: "详情",
                                    on(row, cb) {
                                        _this.goDetail(row, 2);
                                    }
                                },{
                                    label: "同步分润比例",
                                    on(row, cb) {
                                        _this.synchronizationFun(row);
                                    }
                            }]
                            }
                        }
                    ],
                    buttons: []
                },
            };
        },
        created() {
            console.log("room-list created!!");
        },
        deactivated() {},
        activated() {
        },

        methods: {
            onGet(opt) {
                this.myColums = [];
                let dto = {
                    pageNumber: opt.skip,
                    pageSize: opt.limit,
                    type: 1,
                    roleType: 3,
                    ...opt.searchForm
                };
                this.post("/mall-service/merchant/v1/page", dto, {
                    isUseResponse: false
                }).then(res => {
                    for (let i = 0; i < res.data.length; i++) {
                        let item = res.data[i];
                        item.action = [false, false];
                        if (item.state == 0) {
                            item.action = [true, true,false];
                        } else {
                            item.action = [false, true,true];
                        }
                        // 审核通过 且未同步过分润比例的才显示 同步
                        if(item.state == 1) {
                            // 1-已同步过 0-未同步
                            if (item.isSync == 1) {
                                item.action[2] = false;
                            } else {
                                item.action[2] = true;
                            }
                        }else {
                            item.action[2] = false;
                        }

                        item.stateStr = ["待审核", "审核通过"][item.state];
                        item.sexStr = ["男", "女"][item.sex - 1];
                        if (item.cardType == 1) {
                            item.cardTypeStr = "身份证";
                        }
                        item.cardNoStr = item.cardNo ? item.cardNo.slice(0, 2) + '******' + item.cardNo.slice(
                            item.cardNo.length - 2, item.cardNo.length) : '--';
                        item.bankPhone = item.bankPhone ? item.bankPhone.slice(0,3) + '****' + item.bankPhone.slice(item.bankPhone.length - 4, 
                            item.bankPhone.length) : '--'
                        item.address = item.address ? item.address : '--';
                    }
                    opt.cb(res);
                });
            },
            goDetail(row, checkFlage) {
                let path = '';
                path = "/main/merchantManage/dealer/detail";
                this.$router.push({
                    path: path,
                    query: {
                        id: row.id,
                        state: row.state,
                        checkFlage: checkFlage,
                        type: row.type,
                    }
                })
            },
            synchronizationFun(row) {
                let dto = {
                    merchantId: row.id,
                    merchantName: row.name,
                }
                this.post('/mall-service/category/sync',dto,{
                    isUseResponse: true
                }).then(res => {
                    if(res.data.code == 0) {
                        this.$message({
                            message: '同步成功',
                            type: 'success'
                        })
                        this.$refs.myList.onRefresh();
                    }
                })
            },
        }
    };
</script>
<style lang="scss">
   .personalList {
      height: calc(100% - 130px); 
   }
</style>